<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <!--<div class="main-logo">
        <router-link
          :to="{ name: 'main' }"
          class="d-flex align-center">
          <v-img
            :src="appLogo"
            max-height="100px"
            max-width="100px"
            alt="logo"
            contain
            class="me-3 "></v-img>
        </router-link>
      </div>-->
      <v-card class="auth-card card-custom-border-color">
        <!-- logo -->
        <!-- <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            :to="{ name: 'main' }"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title> -->

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Create an account!
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="registerForm"
            @submit.prevent="handleFormSubmit"
          >
            <p
              v-if="errorText"
              class="error--text font-weight-semibold"
            >
              {{ errorText }}
            </p>

            <v-text-field
              v-model="name"
              outlined
              color="alternate"
              :error-messages="errorMessages.name"
              :rules="[validators.required]"
              placeholder="Full name"
              hide-details="auto"
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="email"
              outlined
              color="alternate"
              :error-messages="errorMessages.email"
              :rules="[validators.required, validators.emailValidator]"
              placeholder="john@example.com"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              color="alternate"
              :error-messages="errorMessages.password"
              :rules="[validators.required, validators.passwordValidator]"
              :type="isPasswordVisible ? 'text' : 'password'"
              placeholder="Password"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              class="mb-3"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-text-field
              v-model="confirmPassword"
              outlined
              color="alternate"
              :error-messages="errorMessages.confirmPassword"
              :rules="[validators.required, validators.passwordValidator]"
              :type="isPasswordVisible ? 'text' : 'password'"
              placeholder="Confirm password"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-checkbox
              color="alternate"
              v-model="iAgree"
              hide-details
              class="mt-1"
            >
              <template #label>
                <div class="d-flex align-center flex-wrap">
                  <span class="me-2">I agree to</span><a href="javascript:void(0)" class="link-color">privacy policy &amp; terms</a>
                </div>
              </template>
            </v-checkbox>

            <v-btn
              block
              color="alternate"
              class="mt-6"
              type="submit"
            >
              Sign Up
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            Already have an account?
          </span>
          <router-link :to="{name:'auth-login'}" class="link-color">
            Sign in instead
          </router-link>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <!--<img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >-->

    <!-- tree -->
    <!--<v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>-->

    <!-- tree  -->
    <!--<v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>-->
  </div>
</template>

<script>
import { useRouter } from '@core/utils'
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { emailValidator, passwordValidator, required } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { getCurrentInstance, onUnmounted, ref } from 'vue'
import authuserStoreModule from './apps/authuser/authuserStoreModule'
import { auth } from './apps/firebaseConfig'

export default {
  setup() {
    const AUTHUSER_APP_STORE_MODULE_NAME = 'app-authuser'

    // Register module
    if (!store.hasModule(AUTHUSER_APP_STORE_MODULE_NAME)) {
      store.registerModule(AUTHUSER_APP_STORE_MODULE_NAME, authuserStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AUTHUSER_APP_STORE_MODULE_NAME)) store.unregisterModule(AUTHUSER_APP_STORE_MODULE_NAME)
    })

    // Template refs
    const registerForm = ref(null)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)
    const name = ref('')
    const email = ref('')
    const password = ref('')
    const confirmPassword = ref('')
    const errorMessages = ref([])
    const errorText = ref(null)
    const iAgree = ref(false)

    const finalStep = user => {
      // console.log(user)
      user.ability = [
        {
          action: 'read',
          subject: 'Admin',
        },
        {
          action: 'read',
          subject: 'Public',
        },
      ]
      if (user.email !== 'admin@kadatwork.com') {
        user.ability = [
          {
            action: 'read',
            subject: 'Public',
          },
        ]
      }
      const { ability: userAbility } = user

      localStorage.setItem('accessToken', user.accessToken)
      localStorage.setItem('userData', JSON.stringify(user))

      // Set user ability
      vm.$ability.update(userAbility)

      // Set user's ability in localStorage for Access Control
      localStorage.setItem('userAbility', JSON.stringify(userAbility))

      delete user.ability

      // On success redirect to home
      router.push('/')
    }

    const verifyEmail = async () => {
      await store
        .dispatch('app-authuser/emailVerify')
        .then(userverify => {
          console.log(userverify.user)
          finalStep(userverify.user)
        })
        .catch(err => {
          errorText.value = err.message
          console.log(err.message)
        })
    }

    const handleFormSubmit = async () => {
      errorText.value = null

      const isFormValid = registerForm.value.validate()

      if (!isFormValid) return

      if (password.value !== confirmPassword.value) {
        errorText.value = 'Passwords do not match'
        errorMessages.value = 'Passwords do not match'
      } else if (!iAgree.value) {
        errorText.value = 'You must agree to the terms of use and privacy policy to create an account'
        errorMessages.value = 'You must agree to the terms of use and privacy policy to create an account'
      } else {
        await store
          .dispatch('app-authuser/register', {
            email: email.value,
            password: password.value,
            name: name.value,
          })
          .then(() => {
            finalStep(auth.currentUser)

            /*
            if (!user.emailVerified) {
              console.log('Send Email Verification')
              finalStep(user)
            } else {
              finalStep(user)
            } */
          })
          .catch(err => {
            console.log(err.message)
            errorMessages.value = err.message
          })
      }
    }

    return {
      handleFormSubmit,
      isPasswordVisible,
      name,
      email,
      password,
      confirmPassword,
      registerForm,
      errorMessages,
      errorText,
      iAgree,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
        passwordValidator,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
.auth-wrapper.auth-v1 {
  min-height: calc(var(--vh, 1vh) * 75);
}
.main-logo {
  margin: 0 auto;
  display: table;
  padding: 0 0 20px 0px;
}
.card-custom-border-color {
  border: 1px solid #caa227 !important;
}
.link-color {
  color: #caa227;
}
.v-text-field--outlined {
  border: thin solid var(--v-alternate-base);
  border-color: var(--v-alternate-base);
  color: var(--v-alternate-base);
  caret-color: var(--v-alternate-base);
}
</style>
